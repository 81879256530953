import React, { useMemo } from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, FreeMode } from "swiper";
import { StaticImage } from "gatsby-plugin-image"

import 'swiper/css';
import "swiper/css/navigation";

import Contact from "../../components/sections/contact";

const KanadaYa = () => {

    const imageProps = {
        layout: "fullWidth",
        quality: 45,
        formats: ["auto", "webp", "avif"],
        placeholder: "blurred",
        className: "h-full"
    }

    // const [modal, setModal] = useState(0)
    // const [caption, setCaption] = useState("")

    const InteriorImage = ({index, item}) => {
        switch(index){
            case 1:
                return <StaticImage src={`../../images/brand/minimelts/minimelts-1.jpeg`} alt={item} {...imageProps}/>
            case 2:
                return <StaticImage src={`../../images/brand/minimelts/minimelts-2.jpeg`} alt={item} {...imageProps}/>
            default:
                return <StaticImage src={`../../images/brand/minimelts/minimelts-3.jpg`} alt={item} {...imageProps}/>
        }
    }

    const sliderArray = useMemo(()=> [
        "Minimelts Central Gurney",
        "Minimelts Central Gurney",
        "Minimelts Central Gurney",
    ], [])

    return(
        <Layout>
        <Seo title="Central@Gurney | Minimelts" />

        {
            typeof window !== 'undefined' && 
            <div>
                <div className="container mx-auto px-5">
                    <div className="pt-24 pb-6 flex flex-col items-center justify-center">
                        {/* <div className="w-20 mr-5">
                            <StaticImage src="../../images/brand/brand-2.png" className="transition-basic hover:opacity-50" {...imageProps}></StaticImage>
                        </div> */}
                        <h2 className="text-4xl font-semibold mb-2">Minimelts</h2>
                        <p className="font-light text-gray-600">10am – 10pm daily</p>
                        <p className="font-light text-gray-600">For enquiries: 04-229 0655</p> 
                    </div>
                </div>
                <Swiper
                    className="swiper-tenant"
                    modules={[Navigation, Autoplay, FreeMode]}
                    navigation={true}
                    spaceBetween={8}
                    slidesPerView={1}
                    // centeredSlides
                    breakpoints={{
                        768: {
                            slidesPerView: 3,
                            spaceBetween: 16,
                            // centeredSlides: false,
                            initialSlide: 0
                        },
                    }}
                >
                    {sliderArray.map((item, i) => (
                        <SwiperSlide key={"tenant"+item}>
                            <div className="aspect-video bg-gray-200 relative md:my-5">
                                <InteriorImage index={i+1} item={item}/>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div className="container mx-auto px-5">
                    <div className="pt-10 pb-20 xl:px-16 w-full mx-auto md:w-7/12">
                        <p className="font-light leading-relaxed">          
                          Minimelts is a delightful and innovative treat that puts a unique spin on traditional ice cream. Comprising tiny, vibrant beads of ice cream, Minimelts are flash-frozen using liquid nitrogen, resulting in a velvety texture that dissolves smoothly in your mouth. Ideal for a refreshing and quick indulgence on a warm day, Minimelts come in a range of flavours to suit every palate. Manufacturing deliciousness at the temperature of -150°C, they ensure a delectable experience as it melts upon contact with your taste buds. With its widespread popularity, Minimelts has expanded its reach to 40 countries worldwide, including Japan, the United Kingdom, Canada, Hong Kong, and Singapore. This outlet in Penang marks the brand’s first presence in Malaysia.
                        </p>
                        <img src="/promotion/promotion-13-minimelts.jpg" className="w-full h-full object-cover mt-12"/>
                    </div>
                </div>
                <Contact/>
            </div>
        }

        </Layout>
    )
}

export default KanadaYa
